<template>
  <v-app id="inspire">
    <v-main>

      <v-container
        fluid
        fill-height
      >

        <v-layout
          align-center
          justify-center
        >

          <v-flex
            xs12
            sm6
            md4
          >

            <v-card class="elevation-5">
              <div style='text-align : center ; padding-top : 50px'>
                <v-img
                  contain
                  aspect-ratio='1.7778'
                  src='../assets/opd_logo.png'
                  max-height='230'
                >
                </v-img>
              </div>
              <v-card-text>
                <v-alert
                  v-model='alert'
                  dense
                  type="error"
                >{{alertMessage}}</v-alert>
                <v-form>
                  <v-text-field
                    prepend-icon="person"
                    name="login"
                    label="Login"
                    type="text"
                    v-model="email"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    prepend-icon="lock"
                    name="password"
                    label="Password"
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-dialog
                  v-model="dialog3"
                  overlay-opacity='100'
                  width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                    >
                      Forgot Password
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Forgot Password
                    </v-card-title>

                    <v-text-field
                      prepend-icon="mail"
                      label="Email to receive password reset email"
                      type="text"
                      v-model="resetEmail"
                      class='ma-5'
                    ></v-text-field>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="forgot"
                      >
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  @click='submit'
                >Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { resetPassword } from "../dataservices/adminDataService";
import store from '../store'
export default {
  components: {
   
  },
  data() {
    return {
      alertMessage: null,
      alert: false,
      message: null,
      phoneAuthProvider: null,
      verificationId: null,
      smscode: null,
      user: null,
      loading: false,
      error: null,
      email: null,
      password: null,

      dialog2: false,
      dialog3: false,
      phoneNumber: null,
      resolver: null,
      resetEmail: null,
    };
  },
  mounted() {
     if(store.getters.user.loggedIn){
      this.$router.push("/");
     }
  },
  methods: {
    async forgot() {
      try {
        await resetPassword(this.resetEmail);
        alert("Reset Email Sent");
        this.resetEmail = null;
        this.dialog3 = false;
      } catch (e) {
        alert("something went wrong :" + e);
        this.dialog3 = false;
      }
    },
    submit() {
      this.alertMessage = null;
      this.alert = false;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((response) => {
          this.user = response.user;
          this.$router.push("/");
        })
        .catch(async () => {
       
            this.alertMessage = "Please check your user name and password.";
            this.alert = true;
          
        });
    },
  },
};
</script>


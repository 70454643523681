<template>
  <v-container>
    <v-card>
      <v-card-title>
        Admin Panel
      </v-card-title>
      <v-card-text>
        <v-expansion-panels
          v-model="panel"
          multiple
          accordion
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              Add new user
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref='form'>
                <v-select
                  :items='["User","Admin"]'
                  label='Access Level'
                  :rules="requiredRule"
                  v-model='access'
                >
                </v-select>

                <v-text-field
                  label='Email Address'
                  :rules="requiredRule"
                  v-model='email'
                >
                </v-text-field>
                <v-text-field
                  label='Name (First + Last)'
                  :rules="requiredRule"
                  v-model='name'
                >
                </v-text-field>

                <v-select
                  :items='["Attorney","Social Worker"]'
                  label='Type'
                  :rules="access === 'Admin'  ? [] :  requiredRule"
                  v-model='typeOfUser'
                >
                </v-select>

                <v-btn
                  :loading='btnloading'
                  @click='submit'
                  color='primary'
                >
                  Submit
                </v-btn>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  methods: {
    async submit() {
      this.btnloading = true;
      let valid = this.$refs.form.validate();
      if (valid) {
        try {
          await axios.post(
            "https://dp01-batch-log.uc.r.appspot.com/internal/createUser",
            {
              email: this.email,
              name: this.name,
              role: this.access,
              title: this.typeOfUser,
            }
          );
          this.btnloading = false;
        } catch (e) {
          alert("something went wrong, please check your inputs");
          this.btnloading = false;
        }
      } else {
        return;
      }
    },
  },
  data() {
    return {
      btnloading: false,
      requiredRule: [(v) => !!v || "This field is required"],
      email: null,
      name: null,
      typeOfUser: "",
      access: "User",
      panel: [0],
    };
  },
};
</script>

<style>
</style>
<template>
   <v-dialog
      persistent
      v-model="showBatchLogsDialog"
      :max-width="calMode === 'tape' ? '800px' : '600px'"
    >
    <v-form ref="form">
      <v-card>
    
       
        <v-card-text>

          <v-row>
            <v-col v-if='calMode === "tape"' cols="5">
              <h2>Distribution Line</h2>
              <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            DL #
          </th>
          <th class="text-left">
            Amount
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in transcations"
          :key="item.doc_num"
        >
          <td>{{ item.doc_num }}</td>
          <td>{{ item.amount }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>

 
            </v-col>

            <v-col :cols="calMode === 'tape' ? 7 : 12">
              <v-card-title class="headline">Add {{calMode === "tape" ? "Invoice":"Distribution Line"}}</v-card-title>
              <v-alert type="info">Press Enter On Amount to add new line</v-alert>

       
<v-row
  v-for="(item, index) in batchLogEntries"
  :key="index"
>
  <v-col cols="3">
    <v-text-field
      label="#"
      v-model="item.doc_num"
      :rules="[rules.required]"
    ></v-text-field>
  </v-col>
  <v-col cols="3">
    <v-text-field
      prefix="$"
      label="Amount"
      v-model="item.amount"
      @keydown.enter.prevent="addNewLine"
      type="number"
      @input="updateTotalAmount"
      ref="amountInput"
      :rules="[rules.required, rules.number]"
    ></v-text-field>
  </v-col>
  <v-col cols="6">
  
    <v-btn
      class='ml-3'
      dark
      small
      color="red"
      @click="removeLine(index)"
      v-if="batchLogEntries.length > 1"
    >Remove Line</v-btn>
  </v-col>
</v-row>
<v-row>
  <v-col
    cols="6"
    class="text-subtitle-1"
  >
    Total Amount : ${{ totalAmount }}
  </v-col>
  <v-col
    cols="6"
    class="text-subtitle-1"
  >
    Number of Lines: {{ batchLogEntries.length }}
  </v-col>
</v-row>
            </v-col>
          </v-row>
     
        
        </v-card-text>
        <v-card-actions>
          Total DL : ${{transcations.reduce((accumulator, currentObject) => {
    return accumulator + currentObject.amount;
}, 0)}}
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeDialog"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    </v-dialog>
</template>

<script>
export default {
    methods : {
      closeDialog(){
        let valid = this.$refs.form.validate();
      if (valid) {
        this.$emit("closeDialog");
      }else{
        return;
      }
      },
        updateTotalAmount() {
  let amount = this.batchLogEntries.reduce(
    (total, item) => total + parseFloat(item.amount || 0),
    0
  );

  this.$emit("updateTotalAmount", amount,this.batchLogEntries)
},
        addNewLine() {
    // Generate a new sequential doc_num
    const lastdoc_num =
      this.batchLogEntries[this.batchLogEntries.length - 1].doc_num;
    let newdoc_num = 1;
          
    if (lastdoc_num && !isNaN(lastdoc_num)) {
      newdoc_num = parseInt(lastdoc_num, 10) + 1;
    } 



    this.batchLogEntries.push({ doc_num: newdoc_num, amount: "" });
 
    this.$emit("updateCount", this.batchLogEntries)
    this.updateTotalAmount();

    // Focus on the new line's amount input field
    this.$nextTick(() => {
      const amountInput = this.$refs.amountInput[this.batchLogEntries.length - 1];
      if (amountInput) {
        amountInput.focus();
      }
    });
  },
        removeLine(index) {
      if (this.batchLogEntries.length > 1) {
        this.batchLogEntries.splice(index, 1);

        this.$emit("updateCount", this.batchLogEntries)
        this.updateTotalAmount();
      }
    },
    },
    computed: {
    totalAmount() {
      let calTotal = this.batchLogEntries.reduce(
        (total, item) => total + parseFloat(item.amount || 0),
        0
      );
      return calTotal;
    },
  },
    props : {
      showBatchLogsDialog : Boolean,
        dataSource : Array,
        transcations : Array,
        calMode : String
    },
    data(){
        return{
            rules: {
      required: (value) => !!value || "Required.",
      number: (value) => !isNaN(parseFloat(value)) || "Must be a number.",
    },
            batchLogEntries: [{ doc_num: "1", amount: "" }],
        }
    },
    mounted(){
        if(this.dataSource.length > 0){
            this.batchLogEntries = this.dataSource;
        }
        
    
    },
}


</script>
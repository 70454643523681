<template>
  <v-container>

    <v-dialog
      v-model="dialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Preview Cover Sheet</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn @click="downloadPDFWithOutInput() ,dialog = false" icon>
        <v-icon >mdi-download</v-icon>
      </v-btn>
         
          </v-toolbar-items>
        </v-toolbar>    
        <v-row>
          <v-col cols="9">
            <iframe
        width='100%'
        height='1100px'
        :src='src'/>
          </v-col>
          <v-col cols="3">
            <v-form class="ma-4" v-if="inputs">
              <v-text-field v-model="inputs[0].fiscalMonth" label="Fiscal Month"></v-text-field>
              <v-text-field v-model="inputs[0].prepareBy" label="Prepared By"></v-text-field>
              <v-text-field v-model="inputs[0].enteredBy" label="Entered By"></v-text-field>
              <v-text-field v-model="inputs[0].releaseBy" label="Released By"></v-text-field>
              <v-text-field v-model="inputs[0].finalCheckBy" label="Final Check By"></v-text-field>
            </v-form>
            <v-btn @click="updatePreviewData()">Update</v-btn>
          </v-col>
        </v-row>  
       
      </v-card>
    </v-dialog>



    <v-card>
      <v-card-title>
        All Batch Logs
        <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      </v-card-title>

      <v-data-table
      :items-per-page="30"
        :loading='loading'
        :headers="headers"
        :search="search"
        :items="data"
        :sort-by="['status', 'batch_number']"
        multi-sort
      >

        <template v-slot:item.status="{ item }">
          <v-chip
            :color="getStatusColor(item.status)"
            text-color="white"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn  :style="color(item)" @click='updateLockStatus(item)' title="lock this batch log" icon>
          <v-icon>
            mdi-lock
          </v-icon>
         </v-btn>
         <v-btn  @click='previewPdf(item)' title="download report" icon>
          <v-icon >
            mdi-download
          </v-icon>
         </v-btn>

        
        </template>

        <template v-slot:item.batch_number="{ item }">
          <router-link :to="'/bathLog/' + item.id">{{ item.batch_number }}</router-link>
        </template>
        
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { jsPDF } from "jspdf";
import { PDFDocument } from 'pdf-lib'
import 'jspdf-autotable'
import {data} from "../assets/template/template"
import { OpenPreivegenContractPreview , genContractPreviewBlob} from "../util";
import { getAllLogs, getLog,updateLock, updateLogCoverField } from "../dataservices/logsDataservices";
export default {
  methods: {
    color(item){
      return item.is_lock === "Yes" ? "color : red" : "color : grey"
    },
   async updateLockStatus(item){
      await updateLock(item.id,item.is_lock);
      location.reload();
    },
    prepareItem(item){
      this.inputs = [
            {
              bienniumTitle : "Biennium " + this.getBienniumForCurrentYear(), 
              row1right : item.batch_number.toString(),
              agency: "0560",
              batchDate : item.date,
              batchType : "09",
              batchNumber: item.batch_number.toString(),
              biennium: this.getBienniumForCurrentYear(),
              fiscalMonth: "",
              prepareBy: item.prepared_by ? item.prepared_by : "",
              enteredBy: item.entered_by? item.entered_by : "",
              releaseBy: item.released_by? item.released_by : "",
              finalCheckBy: item.final_check_by? item.final_check_by : "",
              prepareBy_date : item.prepared_by_time ? item.prepared_by_time : "",
              enteredBy_date :  item.entered_by_time ? item.entered_by_time : "",
              finalCheckBy_date :  item.final_check_by_time ? item.final_check_by_time : "",
              releaseBy_date :  item.released_by_time ? item.released_by_time : "",
              transcationNum: item.transaction_count.toString(),
              hashAmount: "$" +item.total_transaction_amount_calc.toString(),
              paymentTotal: "$" + item.total_transaction_amount_calc.toString(),
              inputScreen: "1",
              batch_type : item.batch_type ? item.batch_type : " ",
              programField : item.program ? item.program : " " + " "+ item.sub_program ? item.sub_program : " ",
            },
          ];
    },
     getBienniumForCurrentYear() {
  // Get the current year
  const currentYear = new Date().getFullYear();
  
  // Determine the start of the biennium
  // If it's an even year, subtract 1, otherwise subtract 2 to get to the previous odd year
  const startYear = currentYear - (currentYear % 2 === 0 ? 1 : 2);

  // The end year is always one more than the start year in a biennium
  const endYear = startYear + 2; // We add 2 for a period of two years

  // Format the biennium string
  return `${startYear}-${endYear.toString().substring(2)}`;
},
  genPage2(transcations,tapes,batchDetail){
    
    let doc = new jsPDF();
    const rows = [];

    transcations.forEach((t,i) => {
     
      let tapesAmount = "";
      if(tapes[i]){
        tapesAmount =  "$"+tapes[i].amount.toString()
    }
      rows.push([(t.doc_num >= 9 ? "0" : "00")+(t.doc_num).toString(), "$"+t.amount.toString(),`${batchDetail.batch_number}-` + (i >= 9 ? "0" : "00")+(i+1).toString(),tapesAmount,]);
    })
    //doc.text("Doc #", 150, 25);
    var columns = ["Distribution Line","DL Total : $" + batchDetail.total_transaction_amount_calc, "Doc #",tapes.length > 0 ? "Invoice Total : $" + batchDetail.total_tapes_amount_calc : "Doc Totals", ];


// Only pt supported (not mm or in)
doc = new jsPDF('p', 'pt');
doc.autoTable(columns, rows,{
  tableWidth: 'wrap',
  theme: 'grid', 
  styles : {
    fontSize: 13,
  },
  didDrawPage : function() {
    doc.text("$" + (batchDetail.total_transaction_amount_calc - batchDetail.total_tapes_amount_calc).toString(), 520, 50);
    doc.text("Distribution Line Count :" + transcations.length, 40, 30);
    }
});


    return doc.output('datauristring');
    
  },
  async updatePreviewData(){
    await updateLogCoverField({
          prepareBy : this.inputs[0].prepareBy,
          enteredBy: this.inputs[0].enteredBy,
          releaseBy: this.inputs[0].releaseBy,
          finalCheckBy: this.inputs[0].finalCheckBy,
        },this.inputs[0].batchNumber);
        let item = await getLog(this.inputs[0].batchNumber);
        this.previewPdf(item[0])
  },
  async updatePreview(item = this.selectedItem,transcations = this.transcation.transcations,tapes = this.transcation.tapes){
 
    const blob =  await genContractPreviewBlob(this.inputs, this.template);

      var reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        this.src = reader.result;
        
        const pdfDoc1 = await PDFDocument.load(this.src);
        
       const pdfDoc2 = await PDFDocument.load(this.genPage2(transcations,tapes,item));
       const mergedPdf = await PDFDocument.create();
       const copiedPagesA = await mergedPdf.copyPages(pdfDoc1, pdfDoc1.getPageIndices());
       copiedPagesA.forEach((page) => mergedPdf.addPage(page));
       const copiedPagesB = await mergedPdf.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
       copiedPagesB.forEach((page) => mergedPdf.addPage(page));

       let base64 = await mergedPdf.saveAsBase64();
        this.src = 'data:application/pdf;base64,' + base64
   

      };
  },
    async previewPdf(item){
      this.selectedItem = item;
      this.dialog = true;
      this.transcation = await getLog(this.selectedItem.id);
      this.template = data;
      this.prepareItem(this.selectedItem);
      this.updatePreview();
      
    },
    async downloadPDFWithOutInput(){
      await OpenPreivegenContractPreview(this.inputs, this.template);
    },
    getStatusColor(status) {
      switch (status) {
        case "Available":
          return "green"; // or any other color of your choice
        case "Processing":
          return "orange"; // or any other color of your choice
        case "Complete":
          return "blue"; // or any other color of your choice
        default:
          return "grey"; // or a default color for unknown statuses
      }
    },

    // ... (other methods) ...
  },
  async mounted() {
 
    this.data = await getAllLogs();
    this.loading = false;
  },
  data() {
    return {
      selectedItem : null,
      transcation : null,
      src : null,
      dialog : false,
      template : null,
      inputs : null,
      search: '',
      loading: true,
      headers: [
      { text: "Batch #", value: "batch_number" },
        { text: "Date", value: "date" },
        { text: "Staff", value: "staff" },
       
        { text: "Program", value: "program" },
        { text: "Distribution Line Count", value: "transaction_count" },
        {
          text: "Total Distribution Line Amount",
          value: "total_transaction_amount_calc",
        },
        { text: "Notes", value: "notes" },
        { text: "Being Worked On", value: "is_lock" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      data: [],
    };
  },
};
</script>
<template>
  <v-container>

    <v-alert
      v-if='formData.is_lock === "Yes"'
      color="orange"
      type="warning"
    >Alert: This Batch Log is Currently Being Worked On by Another User.</v-alert>

    <v-card>
      <v-card-title>
        Batch Log #{{id}}
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForm">

          <!-- Date input with date picker -->
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formData.date"
                label="Picker in menu"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.date"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>

          <!-- Staff dropdown -->
          <v-select
            :items="staffList"
            item-text="name"
            item-value="id"
            v-model="formData.staff"
            label="Staff"
            prepend-icon="mdi-account"
          ></v-select>

          <!-- Batch # dropdown -->
          <v-text-field
            v-model="formData.batch_number"
            label="Batch #"
            prepend-icon="mdi-numeric"
          ></v-text-field>
          <v-row>
            <v-col>
              <label for="program">Program:</label>
          <select class="select-material" @change="handleProgramChange" id="program" v-model="formData.program" size="10">
            <option v-for="item,i in programList" :key="i">{{item}}</option>
          </select>
            </v-col>
            
            <v-col>
              <label for="program">Sub Program:</label>
              <select  class="select-material" v-model="formData.sub_program" size="10">
            <option v-for="item,i in subProgramList" :key="i">{{item}}</option>
          </select>
            </v-col>
            <v-col>
              <label for="program">Batch Type:</label>
              <select  class="select-material" v-model="formData.batch_type" size="10">
            <option v-for="item,i in batchTypeList" :key="i">{{item}}</option>
          </select>
            </v-col>
          </v-row>
         

        

        
          
<!--        
          <v-select
            :items="programList"
            @change="handleProgramChange"
            item-text="program"
            item-value="id"
            v-model="formData.program"
            label="Program"
            prepend-icon="mdi-book"
          ></v-select>
          
         


          <v-select
            :items="subProgramList"
            item-text="sub program"
            v-model="formData.sub_program"
            label="Sub Program"
            prepend-icon="mdi-book"
          ></v-select>

          <v-select
            :items="batchTypeList"
            item-text="batch type"
            v-model="formData.batch_type"
            label="Batch Type"
            prepend-icon="mdi-book"
          ></v-select> -->

          <!-- Transaction count text field -->
          <v-text-field
            disabled
            v-model="formData.transaction_count"
            label="Distribution Line Count"
            type="number"
            prepend-icon="mdi-counter"
          ></v-text-field>

          <!-- Total transactions count text field -->
          <v-row align="center">
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                disabled
                v-model="formData.total_transaction_amount_calc"
                label="Total Distribution Line Amount"
                prefix="$"
                type="number"
                prepend-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>

           
           
            <v-col
              cols="12"
              md="4"
            >
              <v-btn
                color="secondary"
                @click="changeMode('transcations')"
              >Add Distribution Line</v-btn>
           
            </v-col>

            
          </v-row>
          <v-text-field
            disabled
            v-model="formData.tapes_count"
            label="Invoice Count"
            type="number"
            prepend-icon="mdi-counter"
          ></v-text-field> 
          
         
          <v-row align="center">
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                disabled
                v-model="formData.total_tapes_amount_calc"
                label="Total Invoice Amount"
                prefix="$"
                type="number"
                prepend-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>

           

            <v-col
              cols="12"
              md="4"
            >
            
              <v-btn
                class="ml-3"
                color="secondary"
                @click="changeMode('tape')"
              >Invoice</v-btn>
            </v-col>

            
          </v-row>
            

          <v-select
            :items="statusOptions"
            v-model="formData.status"
            label="Status"
            prepend-icon="mdi-check-circle"
          ></v-select>

          <!-- Notes text area -->
          <v-textarea
            v-model="formData.notes"
            label="Notes"
            prepend-icon="mdi-note-text"
          ></v-textarea>

          <!-- Submit button -->
          <v-btn
          :loading="loading"
            color="primary"
            type="submit"
          >Save</v-btn>

          <v-btn
            @click='updateLockStatus'
            class='ml-3'
          >{{formData.is_lock === "Yes" ? "Unlock" : "Lock"}}</v-btn>

        
        </v-form>
      </v-card-text>
    </v-card>

   
          
            <calculation 
            @closeDialog="showBatchLogsDialog = false" 
            @updateCount="updateCount" 
            :calMode="calMode" :showBatchLogsDialog='showBatchLogsDialog' @updateTotalAmount="updateAmount" 
            v-if='showBatchLogsDialog' 
            :dataSource="calMode === 'tape' ? tapes : transcations" :transcations="transcations"/>

       

  </v-container>
</template>

<script>
import calculation from "../components/calculation.vue"
import moment from "moment";
import { mapFields } from "vuex-map-fields";
import {
  getLog,
  updateLog,
  updateLock,
} from "../dataservices/logsDataservices";
export default {
  async mounted() {
    //let values = await getDropdownvalues();
    //this.staffList = values.staff;
    //this.programList = values.program;
    this.id = this.$route.params.id;
    let result = await getLog(this.id);
    this.formData = result[0];
    this.formData.date = moment(this.formData.date).format("YYYY-MM-DD");
    this.transcations = result.transcations;
    this.tapes = result.tapes;
    this.handleProgramChange(true);
  },
  components : {
    calculation
  },
  watch: {
  },
  computed: {
    ...mapFields(["user"]),
    
  },
  data: () => ({
    loading : false,
    staffList : [
"Austen",
"Blaise",
"Cara",
"Dani",
"Kathy",
"Kendra",
"Lynette",
"Missy",
"Scott"

    ],
    subProgramList : [],
    batchTypeList : [],
    showForm : false,
    calMode : null,
    statusOptions: ["Available", "Processing", "Complete"],
    id: null,
    showBatchLogsDialog: false,
   
    transcations : [],
    tapes : [],
    menu: false,
    formData: {
      date: "",
      staff: "",
      batch_number: "",
      program: "",
      transaction_count: 0,
      tapes_count : 0,
      total_transaction_amount_calc: 0,
      total_tapes_amount_calc : 0,
      notes: "",
      is_lock: null,
      batch_type: null,
      sub_program : null
    },
    programList: ["Administration","Appellate","Parents Rep","SPAR","Trial Court", "Grants", "71.09","NGRI"], // Populate this list with program data
  }),
  methods: {
    handleProgramChange(init){
      let value = this.formData.program;
      if(init !== true){
        this.formData.sub_program = null;
      this.formData.batch_type = null;
      }
     
      switch(value){
        case "Admin":
        this.subProgramList = ["","Other","Clemency"];
        this.batchTypeList = ["Operating","Disproportionality","IT","Odyssey","Language Services","HR","Other"];
        break;
        case "Appellate":
        this.subProgramList = ["","Other","Post Conviction"];
        this.batchTypeList = ["Attorney Bulk Batch",
        "Attorney",
        "Court Reporters",
        "County Clerks",
        "Misc.",
        "Pro Se",
        "Experts",
        "Travel",
        "Translation",
        "Training",
        "Other",
        "X-Comp"];
        break;
        case "Parents Rep":
        this.subProgramList = ["","Other",
  "Base",
  "Social Workers",
  "Experts",
  "Parity",
  "Parenting Plan",
  "Parent for Parents",
  "Managing Atty",
  "Prefiling",
  "Contractor Training",
  "Contractor Training 4E",
  "Legal Consult Voluntary Placement",
  "PR Caseload Reserve","Title 4E Grant","Appellate Dependencies"];
        this.batchTypeList = [ "Attorney Bulk Batch",
  "Attorney",
  "Experts",
  "Social Worker Bulk Batch",
  "Social Worker",
  "Travel",
  "Training",
  "Other",
  "X-Comp"];
        break;


        case "SPAR":
        this.subProgramList = ["","Other","SPAR - Admin","SPAR - Regional"
 ];
        this.batchTypeList = [  "Attorney Bulk Batch",
  "Attorneys",
  "Experts",
  "Travel",
  "Training",
  "X-Comp",
  "Seattle Clemency",
  "Regional Operations",
  "Other"];
        break;


        
        case "Trial Court":
        this.subProgramList = ["", "Other",
  "Base",
  "RCW10.101 Cities",
  "RCW10.101 Counties",
  "Trial Court Assistance",
  "Conferences",
  "Training",
  "Blake Defense (Admin)",
  "Blake Defense (Contracts)",
  "YAC"
 ];
        this.batchTypeList = [   "Attorney Bulk Batch",
  "Attorneys",
  "Experts",
  "Travel",
  "Training",
  "X-Comp",
  "Other"];
        break;


        case "Grants":
        this.subProgramList = ["", "Other","DOJ","Casey"
 ];
        this.batchTypeList = [  "Attorneys",
  "Experts",
  "Travel",
  "Training",
  "Other"];
        break;


        case "71.09":
        this.subProgramList = ["", "Other"],
        this.batchTypeList = [ "Attorney",
  "Social Worker",
  "Atty & SW",
  "Experts",
  "Litigation",
  "Travel",
  "Training",
  "Other",
  "X-Comp"];
        break;


        case "NGRI":
        this.subProgramList = ["", "Other"],
        this.batchTypeList = [  "Attorney",
  "Attorney - Hourly",
  "Social Worker",
  "Social Worker - Hourly",
  "Atty & SW",
  "Experts",
  "Reimbursement",
  "Travel",
  "Training",
  "Other",
  "X-Comp"];
        break;
      }
    },
    changeMode(m){
      this.calMode = m;
      this.showBatchLogsDialog = true ;
    },
    updateCount(c){
      if (this.calMode === "tape" ){
        this.tapes = c;
        this.formData.tapes_count = c.length;
      } else{
        this.formData.transaction_count = c.length;
        this.transcations = c;
      }
    },
    updateAmount(a,data){

      if (this.calMode === "tape" ){
        this.tapes = data
        this.formData.total_tapes_amount_calc = a;
      } else{
        this.formData.total_transaction_amount_calc = a;
        this.transcations = data;
      }
    },
    back(){
      this.$router.push('/bathLogs')
    },
    async updateLockStatus() {
      await updateLock(this.id,this.formData.is_lock);
      location.reload();
    },

    async submitForm() {
      this.loading = true;
      this.formData.transactions = this.transcations;
      this.formData.tapes = this.tapes;
      await updateLog(this.formData, this.id);
      this.back();
    },
  },
};
</script>

<style scoped>
.select-material >>> option {
  font-size : 18px;
}
.select-material {
  font-family: 'Roboto', 'Segoe UI', sans-serif;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  outline: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.select-material:focus {
  border-color: #6200ea; /* Material Design Indigo A400 */
  box-shadow: 0 2px 8px rgba(0,0,0,0.3);
}

.select-material:hover {
  border-color: #6200ea; /* Slightly darker border on hover for better UI feedback */
}

</style>
import axios from 'axios';

const getAllLogs = async () => {
     const result = await axios.get("https://dp01-batch-log.uc.r.appspot.com/internal/getAllLogs")
     return result.data;
}

const getLog = async (id) => {
     const result = await axios.get("https://dp01-batch-log.uc.r.appspot.com/internal/getLog/"+id)
     return result.data;
}



const getDropdownvalues = async () => {
     const result = await axios.get("https://dp01-batch-log.uc.r.appspot.com/internal/getDropdownvalues/")
     return result.data;
}


const updateLogCoverField  = async (data,id) => {
     const result = await axios.post("https://dp01-batch-log.uc.r.appspot.com/internal/updateLogCoverField/"+id,{
          data :data
     })
     return result.data;
}


const updateLog  = async (data,id) => {
     const result = await axios.post("https://dp01-batch-log.uc.r.appspot.com/internal/updateLog/"+id,{
          data :data
     })
     return result.data;
}

const updateLock  = async (id,v) => {
     v = v === "Yes" ? "No" : "Yes";
     const result = await axios.post("https://dp01-batch-log.uc.r.appspot.com/internal/updateLock/"+id,{
          data : {
               v : v
          }
     })
     return result.data;
}

const getDashboardData = async () => {
     const result = await axios.get("https://dp01-batch-log.uc.r.appspot.com/internal/getDashboardData/")
     return result.data;
}


export {getAllLogs,updateLog,getLog,getDropdownvalues,updateLock, getDashboardData,updateLogCoverField}
<template>
  <v-app id="app">
 
    <v-navigation-drawer
      v-model="drawer"
      v-if='user.loggedIn'
      app
    >
      <v-sheet
        color="grey lighten-4"
        class="pa-4"
      >
        <v-img
          contain
          aspect-ratio='1.7778'
          src='./assets/opd_logo.png'
          max-height='120'
        >
        </v-img>

        <div>Welcome, {{user? user.data.email : ""}}</div>
      </v-sheet>

      <v-divider></v-divider>
      <v-list>

        <div
          :key="item.title"
          v-for="item in items"
        >
          <v-list-group
            v-if='item.items'
            v-model="item.active"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              link
              v-for="child in item.items"
              :key="child.title"
              :to='child.to'
            >

              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :to='item.to'
            :disabled='!item.active'
          >
            <v-list-item-icon>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
        </div>

        <v-list-item @click='signout'>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>

    <v-main>
      <v-app-bar v-if='user.loggedIn'>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>Batch Log Application</v-toolbar-title>

      </v-app-bar>
      <router-view />

    </v-main>

  </v-app>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { mapFields } from "vuex-map-fields";

export default {
  methods: {
    submit(event) {
      event.preventDefault();
      alert("feature coming soon....");
    },
   async signout() {
    await  firebase.auth().signOut();
    location.reload();

    },
  },
  mounted() {},
  computed: {
    ...mapFields(["user", "items"]),
  },
  data: () => ({
    value: null,
    drawer: null,
  }),
};
</script>



import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import batchLogs from "../views/batchLogs.vue"
import Login from "../views/Login.vue"
import firebase from "firebase/compat/app";
import store from '../store'
import batchLogPage from "../views/batchLogPage.vue"

import AdminPage from "../views/Admin.vue"
import "firebase/auth";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
		path: "/login",
		name: "Login",
		component: Login,
	},
  
  {
    path: '/bathLogs',
    name: 'All Batch Logs',
    component: batchLogs,
    meta: { requiresAuth: true },
  },
  {
    path: '/bathLog/:id',
    name: 'Batch Log',
    component: batchLogPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin',
    name: 'Admin Page',
    component: AdminPage,
    meta: { requiresAuth: true , requiresAdmin: true },
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {
		firebase.auth().onAuthStateChanged(async (user) => {
			if (user) {
				let { claims } = await firebase
					.auth()
					.currentUser.getIdTokenResult(true);

				store.commit("set_claims", claims);
				store.commit("set_role", claims.role);
				store.dispatch("filterAdminItem", claims.role);
				store.dispatch("fetchUser", user);
        store.commit("SET_USER", user);
				if (to.meta.requiresAdmin && store.getters.claims.role !== "Admin") {
					next({
						path: "/",
					});
				} else {
					next();
				}
			} else {
				next({
					path: "/login",
				});
			}
		});
	} else {
		next();
	}


});


export default router

<template>
  <div class="home">

   
    <v-card-title>
      Dashboard
    </v-card-title>
    <v-row class='ma-5'>
      <v-col cols='3'>
        <v-card class="mt-5">

          <v-row>
            <v-col cols='7'>
              <v-row>
                <v-col cols='12'>
                  <h1 class='ma-2'>Total Logs</h1>
                </v-col>
                <v-col cols='12'>
                  <h4
                    style='font-size : 35px'
                    class='ml-5'
                  >{{dashboardData.totalLogs}}</h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class='mt-8'
              cols='3'
            ><v-icon style='opacity: 30%;font-size: 100px;'>mdi-file-document-edit</v-icon></v-col>
            <v-col
              cols='12'
              style='text-align: center;margin-bottom: -12px;'
            >
              
            </v-col>
          </v-row>

        </v-card>
      </v-col>

      <v-col cols='3'>
        <v-card class="mt-5">

          <v-row>
            <v-col cols='7'>
              <v-row>
                <v-col cols='12'>
                  <h1 class='ma-2'>New Logs</h1>
                </v-col>
                <v-col cols='12'>
                  <h4
                    style='font-size : 35px'
                    class='ml-5'
                  >{{dashboardData.newLogs}}</h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class='mt-8'
              cols='3'
            ><v-icon style='opacity: 30%;font-size: 100px;'>mdi-checkbox-marked-circle-plus-outline</v-icon></v-col>
            <v-col
              cols='12'
              style='text-align: center;margin-bottom: -12px;'
            >
              
            </v-col>
          </v-row>

        </v-card>
      </v-col>

      <v-col cols='3'>
        <v-card class="mt-5">

          <v-row>
            <v-col cols='7'>
              <v-row>
                <v-col cols='12'>
                  <h1 class='ma-2'>Processing Logs</h1>
                </v-col>
                <v-col cols='12'>
                  <h4
                    style='font-size : 35px'
                    class='ml-5'
                  >{{dashboardData.processingLogs}}</h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class='mt-8'
              cols='3'
            ><v-icon style='opacity: 30%;font-size: 100px;'>mdi-account-group</v-icon></v-col>
            <v-col
              cols='12'
              style='text-align: center;margin-bottom: -12px;'
            >
              <!-- <v-btn
                color='primary '
                block
                to="/contractors"
              >
                More Info <v-icon class='ml-3'>mdi-arrow-right-bold-box-outline</v-icon>
              </v-btn> -->
            </v-col>
          </v-row>

        </v-card>
      </v-col>

      <v-col cols='3'>
        <v-card class="mt-5">

          <v-row>
            <v-col cols='7'>
              <v-row>
                <v-col cols='12'>
                  <h1 class='ma-2'>Completed Logs</h1>
                </v-col>
                <v-col cols='12'>
                  <h4
                    style='font-size : 35px'
                    class='ml-5'
                  >{{dashboardData.completedLogs}}</h4>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class='mt-8'
              cols='3'
            ><v-icon style='opacity: 30%;font-size: 100px;'>mdi-account-plus</v-icon></v-col>
            <v-col
              cols='12'
              style='text-align: center;margin-bottom: -12px;'
            >
              
            </v-col>
          </v-row>

        </v-card>
      </v-col>

    </v-row>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapFields } from "vuex-map-fields";
import  {getDashboardData} from "../dataservices/logsDataservices"

export default {
  name: "Home",
  async mounted() {
    
    this.dashboardData  = await getDashboardData();
   
  },
  methods: {
  
  
  },
  data() {
    return {
      selected: null,
      dashboardData: {
        processingLogs: 0,
        totalLogs : 0,
        newLogs : 0,
        completedLogs : 0
      },
      dialog2: false,
      phoneNumber: null,
      regmessage: null,
      loading: false,
      dialog: false,
      phoneAuthProvider: null,
      verificationId: null,
    };
  },
  components: {
 
  },
  computed: {
    ...mapFields(["user"]),
  },
};
</script>

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import "firebase/compat/auth";
import "@/firebase/firebaseConfig";
import { auth } from "@/firebase/firebaseConfig";
import firebase from "firebase/compat/app";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
axios.interceptors.request.use(
	async (request) => {
		// Do something before request is sent
		let t = "";
		try {
			t = await firebase.auth().currentUser.getIdToken(true);
		} catch {
			t = "";
		}

		request.headers["Authorization"] = `Bearer ${t}`;
		return request;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);



Vue.config.productionTip = false


let app;
auth.onAuthStateChanged(async (user) => {
	if (user) {
		let { claims } = await firebase.auth().currentUser.getIdTokenResult(true);
		store.commit("set_claims", claims);
		store.commit("set_role", claims.role);
		store.dispatch("fetchUser", user);
		store.dispatch("filterAdminItem", claims.role);
	}
	if (!app) {
		app = new Vue({
			store,
			router,
			vuetify,
			render: (h) => h(App),
		}).$mount("#app");
	}
});



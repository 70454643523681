import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
	apiKey: "AIzaSyCOliDZFoK801dqiALDAFlXM5GfSNnVwXk",
	authDomain: "dp01-batch-log.firebaseapp.com",
	projectId: "dp01-batch-log",
	storageBucket: "dp01-batch-log.appspot.com",
	messagingSenderId: "73839794236",
	appId: "1:73839794236:web:5b996c695bff1116844b39"
  };

const admin = firebase.initializeApp(firebaseConfig);
const auth = admin.auth("dp01-batch-log.firebaseapp.com");
const db = admin.firestore();
const { currentUser } = admin;

// firebase collections

export { auth, currentUser, db };

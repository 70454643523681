import axios from 'axios';

const updatePhoneNumber = async (phoneNum) => {
     const result = await axios.post("https://dp01-batch-log.uc.r.appspot.com/internal/updateAdminPhoneNumber",{data:{
      phone : phoneNum,
     }
       
     })
     return result.data;
}

const resetPassword = async (email) => {
   const result = await axios.post("https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyCOliDZFoK801dqiALDAFlXM5GfSNnVwXk",{
      email : email,
      requestType : "PASSWORD_RESET"
   })
     return result.data;
}

const getDataFeed = async () => {
   const result = await axios.get("https://dp01-batch-log.uc.r.appspot.com/internal/getAllDataFeed")
     return result.data;
}
export {updatePhoneNumber, resetPassword, getDataFeed}
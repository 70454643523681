import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from "vuex-map-fields";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	claims: null,
		role: null,
	items: [
		{
			title: "Home",
			icon: "mdi-home",
			to: "/",
			active: true,
			requireAdmin: false,
		},
		{
			title: "All Batch Logs",
			icon: "mdi-clipboard",
			to: "/bathLogs",
			active: true,
			requireAdmin: false,
		},
		{
			title: "Help",
			icon: "mdi-help",
			to: "#",
			active: true,
			requireAdmin: false,
		},
		{
		icon: "mdi-account-eye",
		active: false,
		title: "Admin Only",
		requireAdmin: true,
		items: [
			{
				title: "Admin Settings",
				icon: "mdi-account-eye",
				to: "/admin",
				requireAdmin: true,
			},
			
		],
	}, ],
    user: {
			loggedIn: false,
			data: null,
		},
  },
  getters: {
	getField,
	role(state) {
		return state.role;
	},
	claims(state) {
		return state.claims;
	},
	user(state) {
		return state.user;
	},
	},
	mutations: {
		updateField,
		SET_LOGGED_IN(state, value) {
			state.user.loggedIn = value;
		},
		set_role(state, data) {
			state.role = data;
		},
		set_claims(state, data) {
			state.claims = data;
		},
		SET_USER(state, data) {
			state.user.data = data;
		},

		set_admin_item(state, data) {
			if (data !== "Admin") {
				state.items = state.items.filter((item) => item.requireAdmin === false);
			}
		},
		set_item(state, data) {
			let target = state.items.find((i) => i.title === data);
			if (target) {
				target.active = true;
			} else {
				state.items.forEach((item) => (item.active = false));
			}
		},

	},
	actions: {
		async filterAdminItem({ commit }, role) {
			commit("set_admin_item", role);
		},
		async fetchUser({ commit }, user) {
			commit("SET_LOGGED_IN", user !== null);
			if (user) {
				commit("SET_USER", {
					displayName: user.displayName,
					email: user.email,
				});
			} else {
				commit("SET_USER", null);
			}
		},
	},
  modules: {
  }
})
